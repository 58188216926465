import {ApiRequest} from "~/utils/api-request";
import cookie from "~/utils/cookie";
import type {User, UserMailNotification} from "~/@types";
import {initUserSockets, useSockets} from "~/utils/sockets";

let apiRequest = new ApiRequest('get', 'user/info');
apiRequest.onSuccess(data => {
    onSuccess(data);
});

export const user = reactive<Partial<User> & { init: boolean }>({
    init: false
});

const onSuccess = (data) => {
    for (let key of Object.keys(user)) {
        delete user[key];
    }

    data.init = true;
    Object.assign(user, data);

    const { initUserSockets } = useSockets();

    initUserSockets();
}

const loadUserInfo = async() => {

    if (!cookie.authToken.value()) {
        return new Promise(resolve => {
            resolve();
        })
    }

    return apiRequest.request(true);
}

const reloadUserInfo = async () => {
    apiRequest = new ApiRequest('get', 'user/info');
    apiRequest.onSuccess(data => {
        onSuccess(data);
    });

    await loadUserInfo()
}

const getUserNotifications = async ({ notifications }: { notifications: Ref<UserMailNotification> }) => {
    let apiRequest = new ApiRequest('get', 'user/personal/notifications/mails/get');
    apiRequest.onSuccess(data => {
        Object.assign(notifications.value, data);
    });
    apiRequest.onError(error => {
        console.error('getUserNotifications', error);
    })
    await apiRequest.request();
}

const setUserNotifications = async ({notifications, isLoading}: {
    notification: Ref<UserMailNotification>,
    isLoading: Ref<boolean>
}) => {
    let apiRequest = new ApiRequest('post', 'user/personal/notifications/mails/update', {}, notifications.value);
    apiRequest.onSuccess(data => {
        isLoading.value = false;
    });
    await apiRequest.request();
}

export { loadUserInfo, reloadUserInfo, getUserNotifications, setUserNotifications };
