import Echo from 'laravel-echo';
import config from "./config";
import cookie from "~/utils/cookie";
import Pusher from "pusher-js";

let echoInit = null;

let echoEvents = {
    'bid-added-event': {},
    'menu-updated-event': {},
    'lot-favorite-change-event': {},
    'lot-update-status-event': {},
    'lot-update-status-event-anonymous': {},
    'lot-extended-event': {},
    'lot-extended-event-anonymous': {}
};

const echo = () => {
    if (!process.client) {
        return null;
    }

    if (echoInit) {
        return echoInit;
    }

    window.Pusher = Pusher;

    let pusherConfig = config().pusher;
    if (cookie.authToken.value()) {
        pusherConfig.bearerToken = cookie.authToken.value()
    }

    echoInit = new Echo({
        broadcaster: 'pusher',
        authEndpoint: `https://${pusherConfig.host}/broadcasting/auth`,
        wsHost: pusherConfig.host,
        wsPort: pusherConfig.port,
        wssHost: pusherConfig.host,
        wssPort: pusherConfig.port,
        key: pusherConfig.key,
        cluster: pusherConfig.cluster,
        encrypted: true,
        bearerToken: pusherConfig.bearerToken,
        forceTLS: pusherConfig.forceTLS,
        enabledTransports: ['ws', 'wss'],
    });

    return echoInit;
};

/** @example

 echo().channel('public-channel').listen(".test-broadcast-event", (message) => {
     console.log(message);
 }).subscribe();

 echo().private(`private-channel.${userId}`).listen(".test-broadcast-event", (message) => {
     console.log(message);
 }).subscribe();

 */

export { echo, echoEvents }
