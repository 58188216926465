import { watch } from "vue";
import { echo, echoEvents } from "~/utils/echo";
import { user } from "~/utils/user";
import { useDocumentVisibility } from '@vueuse/core'
import {Channel} from "laravel-echo";
import {EventType} from "~/plugins/event-bus";

let isSubscribed = false;

export const useSockets = () => {
    const visibility = useDocumentVisibility();

    let channels: Channel[] = [];
    const nuxtApp = useNuxtApp();

    watch(visibility, (newValue) => {
        if (newValue === 'hidden') {
            echo()?.leaveAllChannels();

            isSubscribed = false;
            channels = [];
        } else {
            initUserSockets();

            nuxtApp.$eventBus.emit(EventType.DOCUMENT_VISIBLE);
        }
    });

    const initUserSockets = () => {
        if (!isSubscribed && user.id) {
            echo()
                .join(`presence-channel`)
                .stopListening('pusher_internal:member_removed')
                .stopListening('pusher_internal:member_added');

            const bidAddedChannel = echo().join(`presence-channel.${user.broadcastId}`).listen(".bid-added-event", (message) => {
                for (let [key, callback] of Object.entries(echoEvents['bid-added-event'])) {
                    callback(message);
                }
            });

            const lotFavChangeChannel = echo().private(`private-channel.${user.id}`).listen(".lot-favorite-change-event", (message) => {
                for (let [key, callback] of Object.entries(echoEvents['lot-favorite-change-event'])){
                    callback(message);
                }
            });

            const menuUpdatedChannel = echo().private(`private-channel.${user.id}`).listen(".menu-updated-event", (message) => {
                for (let [key, callback] of Object.entries(echoEvents['menu-updated-event'])){
                    callback(message);
                }
            });

            const lotUpdatedStatusChannel = echo().private(`private-channel`).listen(".lot-update-status-event", (message) => {
                for (let [key, callback] of Object.entries(echoEvents['lot-update-status-event'])){
                    callback(message);
                }
            });

            const lotExtendedChannel = echo().private(`private-channel`).listen(".lot-extended-event", (message) => {
                for (let [key, callback] of Object.entries(echoEvents['lot-extended-event'])){
                    callback(message);
                }
            });

            channels.push(bidAddedChannel, lotFavChangeChannel, menuUpdatedChannel, lotUpdatedStatusChannel, lotExtendedChannel);

            channels.forEach((channel) => {
                channel.subscribe();
            })

            isSubscribed = true;
        }
    }

    return {
        initUserSockets,
    }
}